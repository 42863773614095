import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import { Container } from "react-bootstrap"
import { Heading48 } from "../styles/PageStyles"

const PrivacyPolicyId = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout seoTitle="Kebijakan Pribadi">
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <StyledContainer fluid>
          <Heading48 style={{ textAlign: "center", marginBottom: 50 }}>
            {data.markdownRemark.frontmatter.title}
          </Heading48>
          <div
            className="body"
            dangerouslySetInnerHTML={{
              __html: data.markdownRemark.html,
            }}
          />
        </StyledContainer>
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default PrivacyPolicyId

const StyledContainer = styled(Container)`
  padding: 165px 10% 75px 10%;

  * {
    text-align: justify;
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .body {
      p,
      li {
        font-size: 14px;
      }
    }
  }
`

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-policy-id" } }) {
      html
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
